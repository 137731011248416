<template>
  <div class="sharePromote">
    <Header title="邀请推广" rightTitle="推广记录" @rightFuc="openRule" />

    <div class="shareMain">
      <div class="qrCodeBox">
        <div class="qrcode-page">
          <div class="qrcode-header">
            <div class="qrcode-header-left">
              <ImgDecypt class="qrcode-avatar" :src="userInfo.portrait" />
              <p class="qrcode-header-text">{{ userInfo.name }}</p>
            </div>
            <div class="qrcode-header-right">
              <p class="qrcode-invitation">邀请码：</p>
              <p class="qrcode-invitation-code">{{ userInfo.promotionCode }}</p>
            </div>
          </div>
          <div class="qrcode-content">
            <div class="qr-container">
              <div class="qrcode">
                <div ref="qrCodeUrl"></div>
              </div>
              <div class="qrcode-text-container">
                <p class="qrcode-text">输入“{{ userInfo.promoteURL }}”下载</p>
                <p class="qrcode-text">输入我的邀请码即可免费观看</p>
              </div>
            </div>
            <div class="qrcode-buttons">
              <button class="qrcode-button" @click="copyUrl">复制分享链接</button>
              <button class="qrcode-button" @click="showTip">保存图片</button>
            </div>
            <ul class="qrcode-award">
              <li class="qrcode-list-header">
                <span class="qrcode-award-list-text">邀请人数</span>
                <span class="qrcode-award-list-text">每日观影次数</span>
                <span class="qrcode-award-list-text">返利比例</span>
                <!-- <span class="qrcode-award-list-text">奖励</span> -->
              </li>
              <li v-for="(item, index) in award" :key="`award-${index}`" class="qrcode-award-list">
                <span class="qrcode-award-list-text">{{ item.user }}</span>
                <span class="qrcode-award-list-text">{{ item.count }}</span>
                <span class="qrcode-award-list-text">{{ item.award }}</span>
                <!-- <span class="qrcode-award-list-text">{{ item.level }}</span> -->
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="steps"></div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';
import { Toast } from 'vant';
import Header from '@/components/Header';
import ImgDecypt from '@/components/ImgDecypt';

export default {
  name: 'SharePromote',
  components: {
    Header,
    ImgDecypt,
  },
  data() {
    return {
      userInfo: '',
      circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      award: [
        {
          user: '1~5人',
          award: '返利7%',
          count: '5次',
          level: 'VIP会员',
        },
        {
          user: '5~20人',
          award: '返利15%',
          count: '10次',
          level: '2天会员',
        },
        {
          user: '20人以上',
          award: '返利25%',
          count: '20次',
          level: '4天会员',
        },
      ],
    };
  },
  created() {
    this.$store.dispatch('user/getUserInfo');
    this.userInfo = this.$store.getters.userInfo;
    this.generateCode();
  },
  methods: {
    // 生成二维码
    generateCode() {
      this.$nextTick(() => {
        this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: this.userInfo.promoteURL,
          width: 172,
          height: 172,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.L,
        });
      });
    },
    openRule() {
      this.$router.push('/promoRecord');
    },
    copyUrl() {
      this.$copyText(this.userInfo.promoteURL).then(
        (e) => {
          Toast('链接复制成功');
        },
        (e) => {
          Toast('链接复制失败');
        },
      );
    },
    showTip() {
      Toast('请手动截图分享');
    },
  },
};
</script>

<style lang="scss" scoped>
$gradient: linear-gradient(-90deg, #faa982, #f74a82);
$width: 94%;
//   $width: 335px;
.qrcode-page {
  width: $width;
  margin: 0 auto;
  text-align: center;
}
.qr-container {
  height: 300px;
  padding-top: 70px;
  background: url('../../../assets/png/actCredentialsBg1.png') no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
}
.qrcode-header {
  display: flex;
  background-image: $gradient;
  height: 115px;
  width: 335px;
  background: url('../../../assets/png/header_bg.png') no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  margin-left: -2px;
  margin-bottom: -6px;
  border-radius: 5px;
  position: relative;
  z-index: 99;
}
.qrcode-header-left {
  width: 30%;
  text-align: center;
}
.qrcode-header-right {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: white;
  margin-left: 10px;
  margin-top: 20px;
  .qrcode-invitation {
    font-size: 16px;
    font-family: 'REEJI-HonghuangLiGB-Bold';
  }
  .qrcode-invitation-code {
    font-size: 30px;
    margin-top: 5px;
  }
}
.qrcode-header-text {
  display: block;
  color: #fff;
  font-size: 14px;
}
.qrcode-avatar {
  $avatarWidth: 48px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: $avatarWidth;
  height: $avatarWidth;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 0 auto;
  margin-top: 20px;
  overflow: hidden;
  transform: translateZ(100px);
  img {
    width: 100%;
  }
}
.qrcode-avatar {
  margin-bottom: 5px;
  font-size: 18px;
  font-family: 'REEJI-HonghuangLiGB-Bold';
}
.qrcode {
  $qrcodeWidth: 182px;
  width: $qrcodeWidth;
  height: $qrcodeWidth;
  margin: 30pt auto;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}
.qrcode-img {
  $qrcodeImgWidth: 130px;
  width: $qrcodeImgWidth;
  height: $qrcodeImgWidth;
}
.qrcode-text {
  text-align: center;
  font-size: 12px;
  color: #818181;
  width: 80%;
  margin: 0 auto;
  line-height: 1.4;
}
.qrcode-text-container {
  margin-top: 46px;
  margin-top: 60px;
  letter-spacing: 2px;
}
.qrcode-buttons {
  margin: 20px auto 20px;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
}
.qrcode-button {
  display: inline-block;
  background-image: $gradient;
  height: 48px;
  line-height: 48px;
  width: 48%;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
}
.qrcode-list-header,
.qrcode-award-list {
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  height: 30px;
  font-size: 12px;
}
.qrcode-award-list {
  color: #bababa;
}
.qrcode-award-list:nth-child(2n + 1) {
  background-color: #ececec;
}
.qrcode-list-header {
  background: rgb(255, 198, 216);
  border-radius: 5pt 5pt 0 0;
}
.qrcode-award-list-text {
  flex: 1;
  text-align: center;
}

.sharePromote {
  height: 100%;
  background-size: 100% 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;

    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      text-align: center;
      .sharePromotion {
        height: 20px;
        width: 90px;
      }
    }

    .rightBtn {
      width: 80px;
    }
  }

  .shareMain {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 0 10px;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;

    .qrCodeBox {
      // width: 321px;
      // height: 481px;
      margin: 5px auto 0;

      background-size: 100% 100%;
      overflow: hidden;
      position: relative;
      .dottedLine {
        display: flex;
        margin: 22px 10px 0px;
      }
      .dotted {
        height: 1px;
        width: 3px;
        margin: 0 1px;
        background-color: rgb(255, 194, 103);
      }
      .dottedLine1 {
        display: flex;
        margin: 10px 10px;
      }
      .topBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 6px 0;

        .avatarImg {
          width: 62px;
          height: 62px;
          background-image: url('../../../assets/png/appLogoCircle.png');
          background-size: 100% 100%;
          border-radius: 50%;
        }
        .qrText {
          font-size: 16px;
          text-align: center;
        }
      }

      .qrcode {
        width: 142px;
        height: 142px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .promotionCode {
        text-align: center;
        font-size: 22px;
        color: #171717;
        margin-top: 12px;
      }

      .giveAway {
        font-size: 14px;
        text-align: center;
        margin-top: 7px;
        color: rgb(102, 102, 102);
      }

      .officialWebsite {
        margin-top: 1px;
        font-size: 14px;
        text-align: center;
        color: rgb(102, 102, 102);
      }

      .shareBtnBox {
        position: absolute;
        bottom: 18px;
        width: 100%;
        padding: 0 33px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .shareBtn {
          width: 114px;
          height: 38px;
          line-height: 34px;
          border-radius: 4px;
          font-size: 16px;
          text-align: center;
          color: rgb(255, 103, 143);
          border: 2px solid rgb(255, 103, 143);
          box-sizing: border-box;
        }
        .saveBtn {
          color: #fff;
          background-color: rgb(255, 103, 143);
        }
      }
    }

    .steps {
      width: 351px;
      height: 549px;
      margin: 20px auto;
      border-radius: 6px;
      background: url('../../../assets/png/promoSteps.png') no-repeat;
      background-size: 100% auto;
    }
  }
}
</style>
