<template>
  <div class="header flex-center-between">
    <div class="back flex-center-center" @click="$router.go(-1)">
      <svg-icon icon-class="headArrow" />
    </div>
    <div class="title">{{ title }}</div>
    <div class="right-btn" @click="rightBtn">
      <SvgIcon class="rightIcon" :name="rightIcon" v-if="rightIcon" />
      <div v-if="rightTitle">{{ rightTitle }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Header',
  props: {
    title: {
      type: String,
      required: true,
      default() {
        return '标题';
      },
    },
    rightTitle: {
      type: String,
      required: false,
    },
    rightIcon: {
      type: String,
      required: false,
    },
    path: {
      type: String,
      required: false,
    },
    isIcon: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  methods: {
    rightBtn() {
      if (this.path) {
        this.$router.push(this.path);
      } else {
        this.$emit('rightFuc');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  height: 44px;
  padding: 0 14px;
  .back {
    > svg {
      height: 19px;
      width: 19px;
    }
  }
  .title {
    color: rgb(24, 24, 24);
    font-size: 18px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    font-weight: 500;
  }
  .right-btn {
    color: rgb(102, 102, 102);
    font-size: 12px;
    font-weight: 500;
  }

  .rightIcon {
    width: 30px;
    height: 30px;
  }
}
</style>
